import uid from "@/helpers/uid";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { setCookie, getCookie } from "cookies-next";
import { jwtDecode } from "jwt-decode";

const MIN_IMAGES = 8;

export const MAX_IMAGES = 25;

const setAuthToken = (value) => {
  const split = value.split(" ");

  if (split[0] === "Bearer") {
    const decodedToken = jwtDecode(split[1]);
    const expirationTimeInSeconds = decodedToken.exp;
    const expires = new Date(expirationTimeInSeconds * 1000);

    setCookie("auth", value, {
      expires,
    });
  } else {
    throw new Error("Token must be a Bearer token");
  }
};

const createAuthStore = create((set) => ({
  me: {},
  setMe: (value) => set(() => ({ me: value })),
}));

const useAuthStore = () => {
  const store = createAuthStore();

  const authToken = getCookie("auth");

  return { ...store, authToken, setAuthToken };
};

export const initialPhotoshootState = {
  uid: uid(),
  amount: null,
  clothingStyle: null,
  clothingColor: null,
  backdrop: null,
};

const initialFormState = {
  id: "",
  gender: null,
  name: "My Model",
  eyeColor: null,
  ethnicity: null,
  photos: {
    accepted: [],
    rejected: [],
  },
  photoshoots: [initialPhotoshootState],
};

export const useFormStore = create(
  persist(
    (set, get) => ({
      ...initialFormState,
      setGender: (value) =>
        set((state) => {
          const updatedPhotoshoots = state.photoshoots.map((item) => ({
            ...item,
            clothingStyle: null,
            clothingColor: null,
          }));
          return { gender: value, photoshoots: updatedPhotoshoots };
        }),
      setAttribute: (key, value) =>
        set(() => {
          return { [key]: value };
        }),
      setAcceptedPhotos: (value) =>
        set((state) => ({
          photos: {
            ...state.photos,
            accepted: [...state.photos.accepted, ...value],
          },
        })),
      setRejectedPhotos: (value) =>
        set((state) => ({
          photos: {
            ...state.photos,
            rejected: [...state.photos.rejected, ...value],
          },
        })),
      addPhotoshoot: () =>
        set((state) => {
          const updatedItems = [
            ...state.photoshoots,
            { uid: uid(), amount: null },
          ];
          return { photoshoots: updatedItems };
        }),
      updatePhotoshoot: (index, newProperties) =>
        set((state) => {
          const updatedItems = [...state.photoshoots];
          updatedItems[index] = { ...updatedItems[index], ...newProperties };
          return { photoshoots: updatedItems };
        }),
      updateAcceptedPhoto: (photo, newProperties) =>
        set((state) => {
          const acceptedPhotos = state.photos.accepted.map((item) =>
            item.id === photo.id ? { ...item, ...newProperties } : item
          );

          return { photos: { ...state.photos, accepted: acceptedPhotos } };
        }),
      deletePhotoshoot: (index) =>
        set((state) => {
          const updatedItems = state.photoshoots.filter((_, i) => i !== index);
          return { photoshoots: updatedItems };
        }),
      deleteAcceptedPhoto: (id) =>
        set((state) => {
          const updatedItems = state.photos.accepted.filter(
            (photo) => photo.id !== id
          );
          return { photos: { ...state.photos, accepted: updatedItems } };
        }),
      deleteRejectedPhoto: (id) =>
        set((state) => {
          const updatedItems = state.photos.rejected.filter(
            (photo) => photo.id !== id
          );
          return { photos: { ...state.photos, rejected: updatedItems } };
        }),
      getHeadshotsInUse: () =>
        get().photoshoots.reduce(
          (acc, item) => (item.amount?.value ? acc + item.amount.value : acc),
          0
        ),
      getPhotoshootsAreValid: () =>
        get().photoshoots.every(
          (photoshoot) =>
            !!photoshoot.amount &&
            !!photoshoot.clothingStyle &&
            !!photoshoot.clothingColor &&
            !!photoshoot.backdrop
        ),
      getIsFirstStepValid: () => {
        const hasGender = !!get().gender;
        const hasPhotoshoots = get().photoshoots.every(
          (item) =>
            item.amount &&
            item.clothingStyle &&
            item.clothingColor &&
            item.backdrop
        );

        return hasGender && hasPhotoshoots;
      },
      getIsSecondStepValid: () => {
        const hasName = !!get().name;
        const hasEyeColor = !!get().eyeColor;
        const hasEthnicity = !!get().ethnicity;
        const hasProcessedImages = !!get().photos?.accepted?.every(
          (photo) => photo.processed
        );
        const hasMinImages = get().photos?.accepted?.length >= MIN_IMAGES;
        const hasMaxImages = get().photos?.accepted?.length <= MAX_IMAGES;

        return (
          hasName &&
          hasEyeColor &&
          hasEthnicity &&
          hasProcessedImages &&
          hasMinImages &&
          hasMaxImages
        );
      },
      updateState: (newState) =>
        set((currentState) => ({ ...currentState, ...newState })),
      resetState: () =>
        set((currentState) => ({ ...currentState, ...initialFormState })),
    }),
    {
      name: "form-storage",
      partialize: (state) => ({
        // specify whick keys to persist
        id: state.id,
        gender: state.gender,
        name: state.name,
        eyeColor: state.eyeColor,
        ethnicity: state.ethnicity,
        photos: {
          accepted: state.photos.accepted,
          rejected: [],
        },
        photoshoots: state.photoshoots,
      }),
    }
  )
);

export default useAuthStore;
